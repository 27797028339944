<template>
  <VList
    :hide-header="true"
    :rows="displayedRows"
    :headers="headers"
    display-pagination
    :is-loading="isLoading"
    :pagination="pagination"
    @update:current_page="onUpdateCurrentPage"
    @update:per_page="onUpdatePerPage"
  >
    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage :name="item.displayed_name" :color="item.colour" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.name="{ item }">
      <div class="cursor-pointer" @click="onClickOverview(item)">
        <div class="font-medium whitespace-nowrap">
          {{ item.displayed_name }}
        </div>

        <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
          {{ item.displayed_details }}
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.department="{ item }">
      {{ item.displayed_department }}
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item.displayed_status"
          :class="getStatusColor(item.status)"
        />
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.__ACTIONS="{ item }">
      <div class="flex justify-center">
        <VDuplicateAction
          :disabled="isUnauthorized(item)"
          :label="getActionLabel(item, 'duplicate')"
          :item="item"
          :endpoint="endpoint"
          @save="getData"
        />

        <VNewVersion
          :disabled="isUnauthorized(item)"
          :label="getActionLabel(item, 'add_new_version')"
          :item="item"
          :endpoint="endpoint"
          @save="getData"
        />

        <VAction
          :disabled="isUnauthorized(item)"
          :label="getActionLabel(item, 'edit')"
          icon="edit"
          @click="onClickUpdateDetails(item.id)"
        />

        <VDeleteAction
          :disabled="isUnauthorized(item)"
          :label="getActionLabel(item, 'delete')"
          :item="item"
          :text-value="item.displayed_name"
          :delete-function="onClickDelete"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useDisplay from "@/composables/useDisplay";
import useColor from "@/composables/useColor";
import useTraining from "@/composables/useTraining";
import useRead from "@/composables/useRead";
import useSysAdminLock from "@/composables/useSysAdminLock";
import useTexts from "@/composables/useTexts";
// Components
import VList from "@/components/tables/VList";
import VAction from "@/components/tables/VAction";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";
import VDeleteAction from "@/components/VDeleteAction";
import VNewVersion from "@/components/VNewVersion";
import VDuplicateAction from "@/components/VDuplicateAction";

export default {
  components: {
    VList,
    VAction,
    VImage,
    VChip,
    VDeleteAction,
    VNewVersion,
    VDuplicateAction
  },
  setup() {
    // MISC
    const { t } = useI18n();

    // CONSTANTS
    const documentTitle = `${t("app.trainings", 2)} - ${t("app.teaching")}`;

    // COMPUTED
    const displayedRows = computed(() => {
      return rows.value.map(row => ({
        ...row,
        displayed_name: getText(row.texts, "name"),
        displayed_department: row.department?.name || "",
        displayed_details: trimHTML(getText(row.texts, "details")),
        displayed_status: row.status ? t(`app.${row.status}`) : ""
      }));
    });

    // CUSTOM COMPOSABLES
    const { getText } = useTexts();
    const { isUnauthorized, getActionLabel } = useSysAdminLock("training");
    const { trimHTML } = useDisplay();
    const { getStatusColor } = useColor();
    const { endpoint, route, types, searchFields, headers } = useTraining();
    const {
      pagination,
      rows,
      isLoading,
      onClickUpdate,
      onUpdateCurrentPage,
      onUpdatePerPage,
      onClickDelete,
      onClickUpdateDetails,
      getData
    } = useRead({
      endpoint,
      route,
      relations: ["texts", "department"],
      types,
      searchFields
    });

    const onClickOverview = item => {
      if (isUnauthorized(item)) {
        return;
      }

      onClickUpdate(item.id);
    };

    return {
      documentTitle,
      displayedRows,
      // useTraining
      endpoint,
      headers,
      // useColor
      getStatusColor,
      // useSysAdminLock
      isUnauthorized,
      getActionLabel,
      // useRead
      pagination,
      onUpdateCurrentPage,
      onUpdatePerPage,
      onClickDelete,
      rows,
      isLoading,
      onClickOverview,
      onClickUpdateDetails,
      getData
    };
  }
};
</script>
